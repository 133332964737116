<template>
  <nav>
    <router-link to="/">Buscar</router-link>
    |
    <router-link to="/about">Acerca de</router-link>
    |
    <router-link to="/add">Agregar</router-link>
  </nav>
  <div id="staticBackdrop" aria-hidden="true" aria-labelledby="staticBackdropLabel" class="modal fade" data-bs-backdrop="static"
       data-bs-keyboard="false" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <!--        <div class="modal-header">
                  <h5 class="modal-title" id="staticBackdropLabel">Procesando petición</h5>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>-->
        <div class="modal-body">
          <p>Espere por favor...</p>
          <div class="spinner-border text-success" role="status">
            <span class="visually-hidden">Espere por favor...</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <suspense>
    <template #default>
      <router-view/>
    </template>
    <template #fallback>
      <div id="staticBackdrop" aria-hidden="true" aria-labelledby="staticBackdropLabel" class="modal fade" data-bs-backdrop="static"
           data-bs-keyboard="false" tabindex="-1">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 id="staticBackdropLabel" class="modal-title">Procesando petición</h5>
              <button aria-label="Close" class="btn-close" data-bs-dismiss="modal" type="button"></button>
            </div>
            <div class="modal-body">
              Espere mientras termino de procesar su solicitud...
            </div>
          </div>
        </div>
      </div>
    </template>
  </suspense>
</template>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin: 30px;
  background-color: #ffffff;
  background: radial-gradient(circle at 100% 100%, #ffffff 0, #ffffff 3px, transparent 3px) 0% 0%/8px 8px no-repeat,
  radial-gradient(circle at 0 100%, #ffffff 0, #ffffff 3px, transparent 3px) 100% 0%/8px 8px no-repeat,
  radial-gradient(circle at 100% 0, #ffffff 0, #ffffff 3px, transparent 3px) 0% 100%/8px 8px no-repeat,
  radial-gradient(circle at 0 0, #ffffff 0, #ffffff 3px, transparent 3px) 100% 100%/8px 8px no-repeat,
  linear-gradient(#ffffff, #ffffff) 50% 50%/calc(100% - 10px) calc(100% - 16px) no-repeat,
  linear-gradient(#ffffff, #ffffff) 50% 50%/calc(100% - 16px) calc(100% - 10px) no-repeat,
  linear-gradient(0deg, transparent 0%, #48abe0 100%);
  border-radius: 8px;
  padding: 9px;
  box-sizing: border-box;
  opacity: 0.95;
}

body {
  background-image: url('assets/scrabbleBg.webp');
  background-size: 100% auto;
  background-repeat: repeat-y;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

button:disabled,
button[disabled] {
  background-color: #666666;
  cursor: not-allowed;
}

input:disabled,
input[disabled] {
  background-color: #666666;
  cursor: not-allowed;
}
</style>
