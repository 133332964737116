<template>
  <div class="home">
    <scroll-top></scroll-top>
    <div id="divControles">
      <div id="divBuscarPorLetras" class="div-form">
        <div class="input-group mb-3">
          <input id="txtLetters" v-model.trim="letters" aria-label="Por letras" autofocus class="form-control"
                 maxlength="15" pattern="[a-zA-Z]+" placeholder="Por letras" required type="text" v-on:keydown="catchEnter">
          <div class="input-group-append">
            <button id="btnGo" class="btn btn-outline-success" type="button" @click="searchByLetters">Buscar
            </button>
            <button id="btnDel" class="btn btn-outline-danger" type="button" @click="deleteData">Borrar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <p></p>
  <div v-if="searchResultsCount > 0" class="alert alert-success" role="alert">
    Búsqueda: "{{ searchedLetters }}" | Palabras encontradas: {{ searchResultsCount }}
  </div>
  <div id="accordionWords" class="accordion">
    <div v-for="item in searchResultsArray" :key="item.groupId" class="div-form div-words">
      <div class="accordion-item">
        <h2 :id="'heading-' + item.groupLength" class="accordion-header">
          <button :aria-controls="'collapse-' + item.groupLength" :data-bs-target="'#collapse-' + item.groupLength" aria-expanded="false"
                  class="accordion-button collapsed"
                  data-bs-toggle="collapse" type="button">
            Encontré {{ item.wordCount }} palabras con {{ item.groupLength }} letras
          </button>
        </h2>
        <div :id="'collapse-' + item.groupLength" :aria-labelledby="'heading-' + item.groupLength"
             class="accordion-collapse collapse"
             data-bs-parent="#accordionWords">
          <div class="accordion-body">
            <div v-for="palabra in item.groupWords" :key="palabra.wordId" class="">
              <div class="word">
                <a :href="palabra.wordDefinitionLink" class="button two" rel="noreferrer noopener nofollow"
                   target="_blank">{{ palabra.word }}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <p></p>
</template>

<script>
import swal from "sweetalert2";
import {ref} from "vue";
import ScrollTop from "@/components/ScrollTop.vue";

export default {
  name: 'HomeView',
  components: {ScrollTop},
  async setup() {
    let searchResultsArray = ref([]);
    let searchedLetters = ref("");
    let searchResultsCount = ref(0);
    let letters = ref("");
    const emptyArray = [];
    const disableControls = (disableControls) => {
      const nodes = document.getElementById("divControles").getElementsByTagName('*');
      for (let i = 0; i < nodes.length; i++) {
        nodes[i].disabled = disableControls;
      }
    };
    const catchEnter = (keyEvent) => {
      if (keyEvent.key === "Enter") {
        searchByLetters();
      }
    };
    const setSearchedLetters = (sLetters) => {
      searchedLetters.value = sLetters;
      letters.value = sLetters;

      if (localStorage.getItem("searchedLetters")) {
        localStorage.removeItem("searchedLetters");
      }

      localStorage.setItem("searchedLetters", sLetters);
    };
    const setResults = (array) => {
      searchResultsArray.value = array;

      if (localStorage.getItem("searchResultsArray")) {
        localStorage.removeItem("searchResultsArray");
      }

      localStorage.setItem("searchResultsArray", JSON.stringify(array));
    };
    const setCount = (count) => {
      searchResultsCount.value = count;

      if (localStorage.getItem("searchResultsCount")) {
        localStorage.removeItem("searchResultsCount");
      }

      localStorage.setItem("searchResultsCount", count);
    };
    const deleteData = () => {
      setSearchedLetters("");
      setResults(emptyArray);
      setCount(0);
      document.getElementById("txtLetters").focus();
    };
    const checkResults = () => {
      if (searchResultsArray.value.length <= 0) {
        swal.fire({
          icon: 'info',
          title: 'Oops...',
          text: 'No se encontraron resultados'
        });
      }
    };
    const searchByLetters = async () => {
      try {
        const reg = new RegExp('^[a-zA-Z]+$');
        let cleanedLetters = letters.value.replaceAll(" ", "").toLowerCase();

        cleanedLetters = cleanedLetters.replaceAll("á", "a");
        cleanedLetters = cleanedLetters.replaceAll("é", "e");
        cleanedLetters = cleanedLetters.replaceAll("í", "i");
        cleanedLetters = cleanedLetters.replaceAll("ó", "o");
        cleanedLetters = cleanedLetters.replaceAll("ú", "u");
        cleanedLetters = cleanedLetters.replaceAll("ö", "o");
        cleanedLetters = cleanedLetters.replaceAll("ü", "u");
        cleanedLetters = cleanedLetters.replaceAll("ñ", "n");
        letters.value = cleanedLetters;
        disableControls(true);

        if (cleanedLetters.length < 3) {
          await swal.fire({
            icon: 'warning',
            title: 'Oops...',
            text: 'Debes colocar más de 2 letras, sin espacios',
          });
          disableControls(false);
          return;
        }

        if (!reg.test(cleanedLetters)) {
          await swal.fire({
            icon: 'warning',
            title: 'Oops...',
            text: 'Sólo se admiten letras',
          });
          disableControls(false);
          return;
        }

        const uniqueCount = new Set(cleanedLetters).size;

        if (uniqueCount < 3) {
          await swal.fire({
            icon: 'warning',
            title: 'Oops...',
            text: 'Debes colocar más de 2 letras diferentes',
          });
          disableControls(false);
          return;
        }

        const galleryModal = new bootstrap.Modal(document.getElementById('staticBackdrop'), {
          focus: true
        });

        document.getElementById("app").style.opacity = 1;
        galleryModal.show();
        setSearchedLetters(cleanedLetters);
        setResults(emptyArray);
        setCount(0);

        const data = await fetch(encodeURI('https://dictionaryapi.tecnolideres.com/SearchWords/spanish/getbyletters/' + cleanedLetters));

        if (data.status !== 200) {
          await swal.fire({
            icon: 'error',
            title: 'Ocurrió un error',
            text: data.statusText
          });
        } else {
          setResults(await data.json());

          if (searchResultsArray.value.length > 0) {
            let totalCount = 0;
            const wordCounting = function (item, index) {
              totalCount += item.wordCount;
            };

            searchResultsArray.value.forEach(wordCounting);
            setCount(totalCount);

            await swal.fire({
              icon: 'success',
              title: 'Terminé...',
              text: 'Encontré ' + totalCount + ' palabras',
              timer: 1500
            });
          }
        }

        disableControls(false);
        checkResults();
        galleryModal.hide();
        document.getElementById("app").style.opacity = .95;
      } catch (error) {
        console.error(error);
      }
    };
    const fillWithLocalData = () => {
      if (localStorage.getItem("searchResultsArray")) {
        searchResultsArray.value = JSON.parse(localStorage.getItem("searchResultsArray"));
      }

      if (localStorage.getItem("searchedLetters")) {
        searchedLetters.value = localStorage.getItem("searchedLetters");
        letters.value = searchedLetters.value;
      }

      if (localStorage.getItem("searchResultsCount")) {
        searchResultsCount.value = parseInt(localStorage.getItem("searchResultsCount"));
      }
    };

    fillWithLocalData();

    return {
      catchEnter,
      deleteData,
      letters,
      searchByLetters,
      searchedLetters,
      searchResultsArray,
      searchResultsCount
    };
  }
}
</script>

<style scoped>
.div-form {
  margin: auto;
  width: 80%;
}

.div-words {
  text-align: left;
}

/*Estilo links*/
.button {
  text-decoration: none;
  color: #48abe0;
  font-weight: bold;
  font-size: 20px;
  position: relative;
  padding: 10px;
}

a.button.two:before, a.button.two:after {
  opacity: 0;
  transition: all 0.3s ease;
  font-size: 20px;
}

a.button.two:before {
  content: '[';
}

a.button.two:after {
  content: ']';
}

a.button.two:hover:before {
  margin-right: 10px;
  content: '[';
  -webkit-transform: translateX(20px);
  -moz-transform: translateX(20px);
  transform: translateX(20px);
  opacity: 1;
}

a.button.two:hover:after {
  margin-left: 10px;
  content: ']';
  -webkit-transform: translateX(-20px);
  -moz-transform: translateX(-20px);
  transform: translateX(-20px);
  opacity: 1;
}

/*Estilo accordion*/
.acc {
  text-decoration: none;
  color: #48e05c;
  font-weight: bold;
  font-size: 20px;
  position: relative;
  padding: 10px;
}

/*Estilo palabra*/
.word {
  font-size: 10px;
  font-family: "RenoMono", Georgia, serif;
}
</style>
