<template>
  <button id="topBtn" class="btn btn-primary" title="Ir al inicio" @click="goToTop">
    <svg class="bi bi-arrow-up-square" fill="currentColor" height="16" viewBox="0 0 16 16" width="16"
         xmlns="http://www.w3.org/2000/svg">
      <path d="M15 2a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2zM0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm8.5 9.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V11.5z"
            fill-rule="evenodd"/>
    </svg>
  </button>
</template>

<script>
import {defineComponent, onMounted} from "vue";

export default defineComponent({
  name: "scroll-top",
  components: {},
  setup() {
    let topButton;

    onMounted(() => {
      topButton = document.getElementById("topBtn");
    });

    const windowScroll = () => {
      if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
        topButton.style.display = "block";
      } else {
        topButton.style.display = "none";
      }
    };
    const goToTop = () => {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    };

    window.onscroll = function () {
      windowScroll()
    };

    return {goToTop};
  },
});
</script>
<style scoped>
#topBtn {
  display: none;
  position: fixed;
  bottom: 20px;
  right: 35px;
  z-index: 99;
  font-size: 18px;
  cursor: pointer;
  padding: 15px;
}
</style>
